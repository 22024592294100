import "../Register/register.css";
import { z } from "zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as api from "../../../services/api";
import { zodResolver } from "@hookform/resolvers/zod";
import Pagination from "../../Pricing/Pagination";

const schemaFilter = z.object({
    prod: z.string().min(1, "Selecione um produto."),
    manuf: z.string(),
    model: z.string(),
})

type FilterSchema = z.infer<typeof schemaFilter>;

const FilterField = () => {

    const [dataTable, setDataTable] = useState<Array<any>>([{ "Teste-1-1": "", "Teste-1-2": "" }, { "Teste-2-1": "", "Teste-2-2": "" }, { "Teste-1-1": "", "Teste-1-2": "" }, { "Teste-2-1": "", "Teste-2-2": "" }, { "Teste-1-1": "", "Teste-1-2": "" }, { "Teste-2-1": "", "Teste-2-2": "" }, { "Teste-1-1": "", "Teste-1-2": "" }, { "Teste-2-1": "", "Teste-2-2": "" }, { "Teste-1-1": "", "Teste-1-2": "" }, { "Teste-2-1": "", "Teste-2-2": "" }]);

    const [manufs, setManufs] = useState<any[]>([]);
    const [models, setModels] = useState<any[]>([]);

    const { register, handleSubmit, formState: { errors }, setValue, reset, getValues, } = useForm<FilterSchema>({
        mode: "all",
        resolver: zodResolver(schemaFilter),
        defaultValues: {
            prod: "",
            manuf: "",
            model: "",
        }
    });

    const handlePageChange = (newPage: number) => {
        // setIndexSelected(0)
        // setCurrentPage(newPage);
        // setPage(newPage);
    };

    const filter = async (data: FilterSchema) => {

        const { prod, ...rest }: Record<string, unknown> = data;

        const cleanedObj = Object.fromEntries(
            Object.entries(rest)
              .filter(([_, value]) => typeof value === 'string' && value !== '')
          ) as Record<string, string>;

        try {
            let urlParams = new URLSearchParams(cleanedObj).toString();
            urlParams = urlParams.replaceAll("&", "?");
            urlParams = urlParams.replaceAll("manuf", "mounter");
            console.log(urlParams);

            const res = await api.get(`table/list/model/${prod}/?${urlParams}`);
        } catch (error) {
            alert('Error');
        }

        reset();
        setManufs([]);
        setModels([]);

    }

    const getProd = async (prodNum: string) => {

        const resp: any = await api.get(`table/search/mounter/${prodNum}/`);
        await getManuf(resp.data.content);
        return;
    }

    const getManuf = async (manufs: any) => {
        setManufs(manufs);
        return;
    }

    const getModel = async (manufCode: string) => {

        await api.get(`table/search/codes/3/${manufCode}/`).then((response: any) => {
            setModels(response.data.content);
        });
    }

    return (
        <div className="bg-white h-[100%] w-full rounded-lg shadow-sm px-8 py-4">
            <form className="w-full flex gap-4" onSubmit={handleSubmit(filter)}>
                <div className="flex flex-col w-[25%]">
                    <label className="labelRegister">PRODUTO</label>
                    <select className="cadInput w-full" id="" {...register("prod")} onChange={(e) => { getProd(e.target.value) }}>
                        <option value="" selected hidden >PRODUTO</option>
                        <option value="1" >TRATOR</option>
                        <option value="2">HELICÓPTERO</option>
                        <option value="3">SEMIRREBOQUE</option>
                        <option value="4" >CARROCERIA</option>
                        <option value="5">CAMINHÃO E ÔNIBUS</option>
                        <option value="6">COLHEITADEIRA</option>
                    </select>
                    {errors.prod && <span className="text-red-400 text-[12px] font-normal">{errors.prod.message}</span>}
                </div>
                <div className="flex flex-col w-[25%]">
                    <label className="labelRegister">FABRICANTE</label>
                    <select className="cadInput w-full" id="" {...register("manuf")} onChange={(e) => { getModel(e.target.value); }}>
                        <option value="" selected hidden >FABRICANTE</option>
                        {manufs?.map((item: any) => {
                            return <option value={item.mounter_code}>{item.mounter}</option>
                        })}
                    </select>
                    {errors.manuf && <span className="text-red-400 text-[12px] font-normal">{errors.manuf.message}</span>}
                </div>
                <div className="flex flex-col w-[25%]">
                    <label className="labelRegister">MODELO</label>
                    <select className="cadInput  wfull]" id="" {...register("model")}>
                        <option value="" selected hidden>MODELO</option>
                        {models?.map((item: any) => {
                            return <option value={item.model_code}>{item.description}</option>
                        })}
                    </select>
                    {errors.model && <span className="text-red-400 text-[12px] font-normal">{errors.model.message}</span>}
                </div>
                <button className="bg-[#00AEEF] text-white h-10 rounded-full w-[25%] mt-4" type="submit">FILTRAR</button>
            </form>
            <div className="divTable border-2 border-gray-300 h-max mt-4 rounded-lg mb-4">
                <table className="min-w-full border-2 border-gray-300 rounded-lg tableFilter">
                    <thead className="rounded-full">
                        <tr className="border-2 w-full rounded-full">
                            <th className="min-w-[150px]">COD. FABRICANTE</th>
                            <th className="min-w-[150px]">FABRICANTE</th>
                            <th className="min-w-[150px]">COD. MODELO</th>
                            <th className="min-w-[150px]">EIXOS</th>
                            <th className="min-w-[150px]">DESCRIÇÃO</th>
                            <th className="min-w-[150px]">TAMANHO</th>
                            <th className="min-w-[150px]">UNIDADE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataTable.map((obj) => {
                                return (
                                    <tr>
                                        <td>119587</td>
                                        <td>FachinniFachinni</td>
                                        <td>785214</td>
                                        <td>4</td>
                                        <td>DescriptionDescriptionDescriptionDescriptionDescription</td>
                                        <td>10m3</td>
                                        <td>56</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <Pagination currentPage={1} totalPages={1} onPageChange={handlePageChange} />
        </div>
    )
}

export default FilterField;