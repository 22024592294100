import { useState } from 'react';
import CurveLeveling from './CurveLeveling';
import Graphic from './Graphic';
import Core from '../../Core';
import Upload from './Upload';
import Execution from './Execution';
import useWindowSize from '../../../hooks/useWindowSize';
import SearchEnginesRegister from '../Register';

export default function CurveLevelingGraphic() {

    const [navOption, setNavOption] = useState<string>('execution')
    const { width } = useWindowSize()

    return (
        <Core
            withBlueBar
            withNavbar
            path=""
            label=""
        >
            <div className='flex align-middle justify-center'>
                <div>
                    <header className='w-[92vw]'>
                        <nav>
                            <ul className='flex flex-nowrap gap-[.1rem] text-center'>
                                <li className={`flex justify-center w-[100%] items-center text-[12px] py-[.7rem] md:py-[.5rem] md:whitespace-nowrap	 px-[.1rem] md:w-[9rem] lg:text-[16px] sm:px-[1.3rem]  ${navOption === 'execution' ? 'bg-[#00aeef]' : 'bg-[#00000033]'}  rounded-tl-xl flex text-white text-base flex-nowrap hover:cursor-pointer`} onClick={() => setNavOption('execution')}>EXECUÇÃO</li>
                                <li className={`flex justify-center w-[100%] items-center text-[12px] py-[.7rem] md:py-[.5rem] md:whitespace-nowrap	 px-[.1rem] md:w-[9rem] lg:text-[16px] sm:px-[1.3rem] ${navOption === 'up' ? 'bg-[#00aeef]' : 'bg-[#00000033]'}   flex text-white text-base flex-nowrap hover:cursor-pointer`} onClick={() => setNavOption('up')}>UPLOAD</li>
                                <li className={`flex justify-center w-[100%] items-center text-[12px] py-[.7rem] md:py-[.5rem] md:whitespace-nowrap	 px-[.1rem] md:w-[20rem] lg:text-[16px] sm:px-[1.3rem] ${navOption === 'fa_fn' ? 'bg-[#00aeef]' : 'bg-[#00000033]'}  flex text-white text-base flex-nowrap hover:cursor-pointer`} onClick={() => setNavOption('fa_fn')}>FATOR DE CURVA E NIVELAÇÃO</li>
                                <li className={`flex justify-center w-[100%] items-center text-[12px] py-[.7rem] md:py-[.5rem] md:whitespace-nowrap	 px-[.1rem] md:w-[15rem] lg:text-[16px] sm:px-[1.3rem] ${navOption === 'graphic' ? 'bg-[#00aeef]' : 'bg-[#00000033]'}  flex text-white text-base flex-nowrap hover:cursor-pointer`} onClick={() => setNavOption('graphic')}>GRÁFICO DE COMPARAÇÃO</li>
                                <li className={`flex justify-center w-[100%] items-center text-[12px] py-[.7rem] md:py-[.5rem] md:whitespace-nowrap	 px-[.1rem] md:w-[15rem] lg:text-[16px] sm:px-[1.3rem] ${navOption === 'register' ? 'bg-[#00aeef]' : 'bg-[#00000033]'}  rounded-tr-xl flex text-white text-base flex-nowrap hover:cursor-pointer`} onClick={() => setNavOption('register')}>CADASTRO DE MODELO</li>
                            </ul>
                        </nav>
                    </header>
                    <section className='bg-[#f2f2f2] border-[1px] border-[#b9b9b9]  h-max rounded-b-xl w-[92vw]'>
                        {
                            navOption === 'execution' 
                                ? <Execution /> 
                                    : navOption === 'up' 
                                        ? <Upload />
                                            : navOption === 'register'
                                                ? <SearchEnginesRegister />
                                                    : navOption === 'fa_fn'
                                                        ? <CurveLeveling />
                                                            : <Graphic />
                                                        
                        }
                    </section>
                </div>
            </div>
        </Core>
    )
}