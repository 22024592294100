// import NavBar from "../../../components/NavBar";
import "./register.css";
import { z } from "zod";
import { useState } from "react";
import FilterField from "../Filter";
import { useForm } from "react-hook-form";
import * as api from "../../../services/api";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";

const schemaRegister = z.object({
    product: z.string().min(1, "Selecione um produto"),
    mounter: z.string().min(1, "Selecione ou adicione um fabricante"),
    model: z.string().min(1, "Digite um modelo válido"),
    axis: z.string().min(1, "Digite a quantidade de eixos do modelo"),
    description: z.string().min(1, "Digite uma descrição válida"),
    size: z.string(),
    unit: z.string(),
})

type RegisterSchema = z.infer<typeof schemaRegister>;

const SearchEnginesRegister = () => {

    const [mounter, setMounter] = useState<boolean>(false);
    const [manufs, setManufs] = useState<any[]>([]);
    const [models, setModels] = useState<any[]>([]);
    const sizeAnima = (mounterOption: string) => {

        if (mounterOption !== String(mounter)) {
            setMounter(!mounter);
            setValue("mounter", "");
        }
    }

    const getProd = async (prod: string) => {

        const resp: any = await api.get(`table/search/mounter/${prod}/`).then((res: any) => {
            return res;
        }).catch((error: any) => {

            toast.error("Ops, ocorreu um erro! Tente novamente mais tarde", {
                position: toast.POSITION.TOP_RIGHT
            })

        });

        await getManuf(resp.data.content);
        return;
    }

    const getManuf = async (manufs: any) => {
        setManufs(manufs);
        return;
    }

    const { register, handleSubmit, formState: { errors }, setValue, reset, getValues } = useForm<RegisterSchema>({
        mode: "all",
        resolver: zodResolver(schemaRegister),
        defaultValues: {
            product: "",
            mounter: "",
            model: "",
            size: "",
            unit: "",
            axis: "",
            description: "",
        }
    })

    const submeter = async (data: RegisterSchema) => {

        reset();

        try {
            const urlParamProd = data.product;
            const { product, ...newData } = data;
            const res = await api.post(`/table/add/model/${urlParamProd}/`, newData)
                .then((res: any) => {
                    toast.success('Modelo cadastrado com sucesso!', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }).catch((error: any) => {
                    toast.error('Erro ao cadastrar modelo!', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });
        } catch (error) {
            alert('Error');
        }

    }

    return (
        <section className="h-full w-full flex bg-white">
            <div className="bg-white h-full w-[40%] px-16 pt-10">
                <h1 className="text-lg font-semibold text-gray-600">FORMULÁRIO DE CADASTRO</h1>
                <form action="" className="flex flex-col pt-6" onSubmit={handleSubmit(submeter)}>

                    <label className="labelRegister">PRODUTO</label>
                    <select className='cadInput selectInput' id="" {...register("product")} onChange={(e) => getProd(e.target.value)}>
                        <option value="" selected disabled hidden>SELECIONE UM PRODUTO</option>
                        <option value="1" >TRATOR</option>
                        <option value="2">HELICÓPTERO</option>
                        <option value="3">SEMIRREBOQUE</option>
                        <option value="4" >CARROCERIA</option>
                        <option value="5">CAMINHÃO E ÔNIBUS</option>
                        <option value="6">COLHEITADEIRA</option>
                    </select>
                    {errors.product && <p className="text-red-400 text-[12px] mb-2">{errors.product.message}</p>}

                    <label className="labelRegister">FABRICANTE</label>
                    <div className="w-full flex gap-2" >
                        <select className={`cadInput ${mounter ? 'shrinkWidthClass' : 'increaseWidthClass'}`} id="" {...(!mounter ? register("mounter") : {})} onClick={() => sizeAnima('false')} >
                            <option value="" selected disabled hidden>{mounter ? '' : 'SELECIONE O FABRICANTE'}</option>
                            {manufs?.map((item: any) => {
                                return <option value={item.mounter_code}>{item.mounter}</option>
                            })}
                        </select>
                        <input type="text" className={`cadInput ${mounter ? 'increaseWidthClass' : 'shrinkWidthClass'}`} placeholder={`${mounter ? 'NOVO FABRICANTE' : '+'}`} onClick={() => sizeAnima('true')} {...(mounter ? register("mounter") : {})} />
                    </div>
                    {errors.mounter && <p className="text-red-400 text-[12px] mb-2">{errors.mounter.message}</p>}

                    <label className="labelRegister">MODELO</label>
                    <input type="text" className="cadInput" placeholder="DIGITE O MODELO" {...register("model")} />
                    {errors.model && <p className="text-red-400 text-[12px] mb-2">{errors.model.message}</p>}

                    <label className="labelRegister">EIXOS</label>
                    <input type="text" className="cadInput" placeholder="DIGITE A QUANTIDADE DE EIXOS" {...register("axis")} />
                    {errors.axis && <p className="text-red-400 text-[12px] mb-2">{errors.axis.message}</p>}

                    <label className="labelRegister">DESCRIÇÃO</label>
                    <input type="text" className="cadInput" placeholder="DIGITE A DESCRIÇÃO" {...register("description")} />
                    {errors.description && <p className="text-red-400 text-[12px] mb-2">{errors.description.message}</p>}

                    <label className="labelRegister">TAMANHO</label>
                    <input type="text" className="cadInput" placeholder="DIGITE O TAMANHO" {...register("size")} />
                    {errors.size && <p className="text-red-400 text-[12px] mb-2">{errors.size.message}</p>}

                    <label className="labelRegister">UNIDADE</label>
                    <input type="text" className="cadInput" placeholder="DIGITE A UNIDADE" {...register("unit")} />
                    {errors.unit && <p className="text-red-400 text-[12px] mb-2">{errors.unit.message}</p>}

                    <button className="bg-[#00AEEF] text-white h-10 rounded-full mt-4" type="submit">Cadastrar</button>

                </form>
            </div>
            <div className="bg-white h-[100%] w-[60%] pxl-2 pr-16 pt-10">
                <FilterField />
            </div>
        </section>
    )
}

export default SearchEnginesRegister;