import { Doc } from '../pages/Doc'
import Users from '../pages/User/Users'
import NavBar from '../components/NavBar'
import Histories from '../pages/Histories'
import { Pricing } from '../pages/Pricing'
import Dashboard from '../pages/Dashboard'
import UsersList from '../pages/User/UsersList'
import MyAccount from '../pages/User/MyAccount'
import PricingTable from '../pages/PricingTable'
import Companies from '../pages/Company/Companies'
import StatisticsScreen from '../pages/StatisticsScreen'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import CreateOrUpdateUser from '../pages/User/CreateOrUpdateUser'
import CreateOrUpdateCompany from '../pages/Company/CreateOrUpdateCompany'
import CurveLevelingGraphic from '../pages/SearchEngines/Curve_leveling_graphic'

export default function AdminRoutes(): JSX.Element {

  return (
    <BrowserRouter>
    <NavBar />
      <Routes>
        <Route path='*' element={<Dashboard />}/>
        <Route path='/' element={<Dashboard />}/>
        <Route path='/histories' element={<Histories />} />
        <Route path='/companies' element={<Companies />}/>
        <Route path='/company/:id' element={<CreateOrUpdateCompany />}/>
        <Route path='/new-company' element={<CreateOrUpdateCompany />}/>
        <Route path='/users/:id' element={<Users />}/>
        <Route path='/userslist/' element={<UsersList />}/>
        <Route path='/user/:id' element={<CreateOrUpdateUser />}/>
        <Route path='/my-account/:id' element={<MyAccount />}/>
        <Route path='/new-user' element={<CreateOrUpdateUser />}/>
        <Route path='/doc' element={<Doc />} />
        <Route path='/pricing' element={<Pricing />} /> 
        <Route path='/pricingTable' element={<PricingTable />} /> 
        <Route path='/statistics' element={<StatisticsScreen />} />
        <Route path='/curve_leveling_graphic' element={<CurveLevelingGraphic />} />
      </Routes>
    </BrowserRouter>
  )
}