import { FaArrowAltCircleDown } from "react-icons/fa";
import Pagination from "../Pagination";
import { useEffect, useState } from "react";
import * as api from "../../../services/api"
import { toast } from 'react-toastify'
import { baseURL } from "../../../services/api"

interface TableRowData {
    file_name: string;
    created_by_user_email: string;
    created_at: string;
    updated_at: string;
    status_detail: string;
    total_lines: number;
    processed_lines: number;
    processed_lines_error: number;
    exportarResultado: string;
    worksheet_id: string;
}
export default function Accompaniment() {
    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(today.getMonth() - 1);

    const formatDate = (date: Date) => {
        return date.toISOString().split("T")[0];
    };

    const [initialDate, setInitialDate] = useState<string>(formatDate(oneMonthAgo));
    const [finalDate, setFinalDate] = useState<string>(formatDate(today));
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [tableData, setTableData] = useState<TableRowData[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    
    const fetchData = () => {
        setLoading(true);
    
        api.get(
            `/table/manage_pricing/list/?start_date=${initialDate}&end_date=${finalDate}&page=${currentPage}&page_size=${rowsPerPage}`
        )
        .then((response: any) => {
            if(response.status === 200) {
                setTableData(response?.data.content.results || []);
                setTotalPages(response.data.content.pages);
            } else {
                toast.warn("Não há dados para listar", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        })
        .catch((error) => {
            toast.error("Erro ao buscar dados", {
                position: toast.POSITION.TOP_RIGHT,
            });
        })
        .finally(() => {
            setLoading(false);
        });
    };
    
    
    useEffect(() => {
        fetchData();
    }, [currentPage, rowsPerPage]);

    function getCookie(name: any) {
        let cookie: any = {};

        document.cookie.split(';').forEach(function (el) {
            let [k, v] = el.split('=');
            cookie[k.trim()] = v;
        })
        return cookie[name];
    }

    const handleExport = async (worksheet_id: string) => {    
        const myCookie = getCookie("@Somos:access");
  
        const xlsxFileRoute = `${baseURL}/table/pricing/download/monitor/?worksheet_id=${worksheet_id}`
  
        const options = {
          method: 'GET',
          headers: {
            'Content-Type': 'multiplataform/form-data',
            Authorization: `Bearer ${myCookie}`
          }
        };
  
        fetch(xlsxFileRoute, options)
          .then(response => {
            return response.blob()
          })
          .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'arquivo.xlsx');
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
          })
          .catch(err => console.error(err));
    }

    const formatDateTime = (isoString: any) => {
        const date = new Date(isoString);
        const formattedDate = date.toLocaleDateString('pt-BR');
        const formattedTime = date.toLocaleTimeString('pt-BR', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
        return `${formattedDate} ás ${formattedTime}`;
    };

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChangeAcc = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setRowsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const handleFilterChange = () => {
        setCurrentPage(1); 
        fetchData();
    };

    return (
        <div
            className="gap-4 w-full h-max flex min-h-[30vh] items-center flex-col justify-center pt-[3rem] p-3 pb-4 rounded-bl-xl rounded-br-xl border-[1px] border-[#b9b9b9] bg-white"        >
            <div className="flex flex-col justify-center w-[100%] gap-4">
                <div className="flex flex-col gap-4 sm:flex-row sm:items-end ">
                    <div className="flex flex-col items-start">
                        <label htmlFor="initialDate">Data inicial</label>
                        <input className=" rounded-full" type="date" id="initialDate" name="initialDate" value={initialDate} onChange={(e) => setInitialDate(e.target.value)} />
                    </div>
                    <div className="flex flex-col items-start">
                        <label htmlFor="finalDate">Data final</label>
                        <input className=" rounded-full" type="date" id="finalDate" name="finalDate" value={finalDate} onChange={(e) => setFinalDate(e.target.value)} />
                    </div>

                    <div>
                        <button onClick={handleFilterChange} className="w-full bg-[#00AEEF] h-11 rounded-full px-4 py-1 text-white hover:bg-[#006991] transition-all duration-500" >FILTRAR</button>
                    </div>
                </div>

                <div className="flex justify-end items-center w-full ">
                    <div className="relative">
                        <select
                            id="rowsPerPage"
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChangeAcc}
                            className="appearance-none bg-transparent text-gray-700 rounded-full h-[36px] py-1 w-[5rem] text-center md:h-[48px]"
                        >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={50}>50</option>
                            <option value={60}>60</option>
                        </select>
                    </div>
                </div>
            </div>
            <div
                className="divTable w-[100%] rounded-2xl overflow-x-scroll overflow-y-hidden border border-gray-300 my-4 shadow-md"
            >
                <table className="w-full border-collapse text-center">
                    <thead className="bg-[#00AEEF] text-white font-bold">
                        <tr>
                            <th className="p-[.8rem]">Arquivo</th>
                            <th className="p-[.8rem]">Criado por</th>
                            <th className="p-[.8rem]">Criado em</th>
                            <th className="p-[.8rem]">Atualizado em</th>
                            <th className="p-[.8rem]">Status</th>
                            <th className="p-[.8rem]">Total de Linhas</th>
                            <th className="p-[.8rem]">Processadas com sucesso</th>
                            <th className="p-[.8rem]">Processadas com erro</th>
                            <th className="p-[.8rem]">Exportar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading === true ? 
                        <div className="flex items-center justify-center mt-4 mb-4">
                            <div className="w-8 h-8 border-4 border-[#00aeef] border-t-transparent rounded-full animate-spin"></div>
                        </div> :
                            <>
                                {tableData.map((row, index) => (
                                    <tr
                                        key={index}
                                        className="border-b border-gray-300 hover:bg-gray-100"
                                    >
                                        <td className="p-[.8rem]">{row.file_name}</td>
                                        <td className="p-[.8rem]">{row.created_by_user_email}</td>
                                        <td className="p-[.8rem]">{formatDateTime(new Date(row.created_at))}</td>
                                        <td className="p-[.8rem]">{formatDateTime(new Date(row.updated_at))}</td>
                                        <td className="p-[.8rem]">{row.status_detail}</td>
                                        <td className="p-[.8rem]">{row.total_lines}</td>
                                        <td className="p-[.8rem]">{row.processed_lines}</td>
                                        <td className="p-[.8rem]">{row.processed_lines_error != null ? row.processed_lines_error : 0 }</td>
                                        <td className="p-[.8rem]">
                                            <button
                                                className="text-[#00AEEF] hover:text-cyan-800 focus:outline-none"
                                                onClick={() => handleExport(row.worksheet_id)}
                                            >
                                                <FaArrowAltCircleDown size={20} />
                                            </button>
                                        </td>
                                    </tr>
                                ))}

                            </>
                        }
                    </tbody>
                </table>
            </div>
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
        </div>
    )
}